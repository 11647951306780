<template>
  <div
    class="context_menu-header"
    :style="specialColor"
    @click="contextClick">
    <span
      v-if="context.prename"
      class="prename"
      data-custom-brand
      :class="itemClass">
      {{ context.prename }}
    </span>
    <a
      v-if="showUrlItem && !hasChildren"
      :href="getFullUrlWithQuery(context.url)"
      data-custom-brand
      :class="itemClass">
      {{ context.name }}
    </a>
    <span
      v-if="!showUrlItem && !hasChildren"
      data-custom-brand
      :class="itemClass">
      {{ context.name }}
    </span>
    <a
      v-if="hasChildren"
      data-custom-brand
      :class="itemClass">
      {{ context.name }}
    </a>
  </div>
</template>

<script>
  import useNavigatorDetect from 'CommonComposables/navigatorDetect.js';
  import { computed } from 'vue';
  import UtilsURL from 'CommonUtils/url';
  import { mapGetter } from 'CommonUtils/store/state.js';
  import UtilsAnalytics from 'CommonUtils/analytics.js';

  export default {
    name: 'ContextMenuHeader',
    props: {
      context: {
        type: Object,
        required: true,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['context-click'],
    setup(props, { emit }) {
      const { isDesktopDevice, isTabletDevice } = useNavigatorDetect();
      const internals = mapGetter('page/getInternals');
      const baseUrl = mapGetter('page/getBaseUrl');

      const showUrlItem = computed(() => {
        return props.context.url;
      });

      const hasChildren = computed(() => {
        return props.context.has_childrens;
      });

      const itemClass = computed(() => {
        return {
          active: props.isActive,
          ...specialClass(props.context.special),
        };
      });

      const specialClass = (special) => {
        if (typeof special === 'string') {
          return { ['special_' + special]: special !== 'none' };
        } else if( typeof special === 'object') {
          return {
            special_color: Boolean(special.color),
            special_bold: Boolean(special.bold),
          };
        }
      };

      const specialColor = computed(() => {
        return props.context.special?.color ? `--special-color: ${props.context.special.color}` : null;
      });

      const contextClick = () => {
        if (hasChildren.value && (!isDesktopDevice.value || isTabletDevice.value)) {
          emit('context-click', props.context.name);
        }
        if (showUrlItem.value) {
          UtilsAnalytics.sendContextMenuItemAnalytics(props.context, 'click botonera');
        }
      };

      const getFullUrlWithQuery = (url) => {
        return UtilsURL.getFullUrlWithQuery({
          url,
          baseUrl: baseUrl.value,
          query: internals.value,
        });
      };
      return {
        contextClick,
        showUrlItem,
        hasChildren,
        getFullUrlWithQuery,
        itemClass,
        specialColor,
      };
    },
  };
</script>
